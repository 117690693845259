.reservationParent {
  background: var(--offWhite);
  border: "1px solid red";
}
.ReservationDiv {
  background: white;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: Clarimo;
}

.comp-style {
  color: black;
  text-decoration: none;
  padding: 1rem 1rem;
  align-items: left;
}

.datepicker-style {
  color: rgb(41, 41, 43);
  background: rgb(235, 235, 235);

  text-align: center;
  border-style: solid;
  border-color: #01916d;
}

.dropdown-style {
  color: rgb(41, 41, 43);
  background: rgb(235, 235, 235);
  padding: 5px 10px;
  position: relative;
  align-items: center;

  border-style: solid;
  border-color: #01916d;
}

.dropdown-style a:hover {
  color: rgb(247, 214, 178);
}

.button-style {
  background-color: #01916d;
  border-style: solid;
  border-color: #01916d;
  border-radius: 4px;
  color: white;
  padding: 1px 10px;
  text-decoration: none;
  margin: 4px 2px;
  margin-left: 20px;
  cursor: pointer;
}

.dropdown-style:hover .dropdown-style-content {
  display: block;
}

.error {
  color: red;
}

@media screen and (max-width: 750px) {
  .ReservationDiv {
    height: 450px;
    flex-direction: column;
  }
}

.react-datepicker-popper {
  z-index: 3;
}
