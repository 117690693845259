.CancelDiv {
  background: cadetblue;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.Success-Message {
  background: rgb(235, 235, 235);
  color: #000000;
  font-family: Clarimo;
  font-weight: normal;
  margin: 10px auto;
  padding: 3px;
  text-align: center;
  vertical-align: bottom;
  font-size: 1.5rem;
}

.Message {
  background: rgb(235, 235, 235);
  color: red;
  font-size: 1.2rem;
  font-family: Clarimo;
  font-weight: bold;
  text-align: center;
  display: block;
}
.WarningMessage {
  background: rgb(235, 235, 235);
  color: black;
  font-size: 1.2em;
  font-family: Clarimo;
  font-weight: bold;
  text-align: center;
  display: block;
}
.button-style {
  background-color: #01916d;
  border-style: solid;
  border-color: #01916d;
  border-radius: 4px;
  color: white;
  padding: 1px 10px;
  text-decoration: none;
  margin: 4px 2px;
  margin-left: 20px;
  cursor: pointer;
  font-size: 1.2rem;
  font-family: Clarimo;
}
