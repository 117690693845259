h5 {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 1.5em;
  color: black;
}

.login-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: "Clarimo UD PE", Helvetica, Sans-Serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 35px;
  text-align: left;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  font-family: "Clarimo UD PE", Helvetica, Sans-Serif;
}

.login-login {
  height: 28rem;
  width: 28rem;
}

.login-account {
  height: 38rem;
  width: 28rem;
}

.input-style {
  color: black;
  text-decoration: none;
  padding: 1rem 1rem;
  align-items: left;
}
.text-input {
  height: 2.5rem;
  width: 22rem;
  background-color: white;
  border: 1px solid #d7d7d7;
}

.btn-div {
  position: relative;
  padding: 15px;
}

.signIn-button {
  height: 40px;
  width: 100%;
  text-align: center;

  color: #fff;
  background-color: #01916d;
  border: 1px solid #01916d;
  font-weight: 400;
  line-height: 29px;
  position: relative;
  padding: 5px 20px 5px 20px;
  text-indent: 0;
}
