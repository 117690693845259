:root {
  --primaryColor: #af9a7d;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
body {
  padding-top: 2px;
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: "Clarimo UD PE", Helvetica, Sans-Serif;
  line-height: 1.4;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */

.gmd-logo {
  display: relative;
  color: rgb(115, 115, 115);
  font-size: 20px;
  font-family: "Clarimo UD PE", Helvetica, Sans-Serif;
  margin-top: 15px;
  float: right;
  right: 0px;
}

.navbar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--White);
  font-size: 1.2rem;
  font-family: "Clarimo UD PE", Helvetica, Sans-Serif;
  z-index: 1;
}
.nav-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.nav-icon {
  font-size: 1rem;
  color: var(--primaryColor);
}

.userInfoBar {
  border: none;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.nav-links {
  background: rgb(235, 235, 235);
  align-content: left;
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s linear;
  list-style: none;
}

.show-nav {
  height: 200px;
}
.nav-links a {
  text-decoration: none;
  padding: 1rem 0;
  color: var(--mainBlack);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: var(--primaryColor);
}

@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
  }
  .nav-links a {
    display: block;
    margin: 0 1rem;
    padding: 0.5rem 0.5rem;
  }
  .nav-links select {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
  .nav-links label {
    text-decoration: none;
    padding: 0.5rem 0;
    color: black;
    transition: var(--mainTransition);
    text-align: center;
    font-size: 0.5rem;
    font-weight: 700;
    letter-spacing: var(--mainSpacing);
  }
}
/* end of navbar */
/* Hero */
.defaultHero {
  min-height: calc(45vh - 50px);
  margin: 0rem 1rem 1rem;
  /*background: url("./images/deskReservationHome.jpg") center/cover no-repeat;*/
  display: flex;
  align-items: center;
  justify-content: center;
}
.deskReaervationMap {
  min-height: 40vh;
  align-items: right;
  display: flex;
  justify-content: right;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.banner h1 {
  font-size: 2.5rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */

.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}

.container {
  position: relative;
}

.deskReaervationMap {
  min-height: 40vh;
}

.tooltip {
  position: absolute;
  color: black;
  padding: 10px;
  background: transparent;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 10px;
  pointer-events: none;
  z-index: 1000;
}

.loaderDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.desk-logo {
  display: flex;
  height: 38.3vmin;
  pointer-events: none;
}

.default-map {
  align-items: center;
  min-height: 40vh;
  width: 100%;
  height: 50%;
}

.ff-logo {
  height: 50px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .gmd-logo {
    display: none;
  }

  .container {
    height: auto;
    width: auto;
  }
}
